$(document).ready(function() {
    $('#login-form').on('submit', function(event) {
        event.preventDefault();
        let formData = $(this).serialize();
        $.ajax({
            url: $(this).attr('action'),
            method: 'POST',
            data: formData,
            success: function(response) {
                window.location.href = '/dashboard';
            },

            error: function(xhr) {
                let errors = xhr.responseJSON.errors;
                let errorHtml = '';
                $.each(errors, function(key, value) {
                    errorHtml += value[0];
                });

                $('#login-errors').html(errorHtml).removeClass('d-none');
            }
        });
    });

    // navbar navigation
    $('#side-menu li[data-url]').on('click', function() {
        window.location.href = $(this).data('url');
    });

    // logout btn
    $('#logout-link').css('cursor', 'pointer');
    $('#logout-link').on('click', function(event) {
        event.preventDefault();
        $('#logout-form').submit();
    });

    // toggle mobile menu
    $('#sidebar-toggle').on('click', function () {
        $('.side-menu').toggleClass('side-menu-open');
    });
});
